import fetch from "unfetch";
let baseUrl = "https://www.mos.ru";

if (window.location.hostname === "localhost") {
  baseUrl = "";
}

if (window.location.hostname.match(/([a-z]+-)?[actr]-tech.mos.ru/)) {
  baseUrl = window.location.origin;
}

const configUrl = `${baseUrl}/upload/common/places/config.json`;
const bannerFileName = "banner.html";

let hasTouchScreen = false;

if ("maxTouchPoints" in navigator) {
  hasTouchScreen = navigator.maxTouchPoints > 0;
} else {
  let mQ = window.matchMedia && matchMedia("(pointer:coarse)");
  if (mQ && mQ.media === "(pointer:coarse)") {
    hasTouchScreen = !!mQ.matches;
  } else if ("orientation" in window) {
    hasTouchScreen = true; // deprecated, but good fallback
  }
}

interface BannerSettings {
  element: HTMLElement;
  onRender(): any;
  onError(): any;
  params: {
    p1: string;
  };
}

const adfoxApiReplacer = {
  // _config: [
  //   {
  //     "domain": ["www.mos.ru", "localhost"],
  //     "path": ["/knigi/**"],
  //     "places": {
  //       "bxgae": "/upload/common/places/74697a65722d6e65745f5f174697a65722d6e65745f5f"
  //     }
  //   },
  //   {
  //     "domain": ["www.mos.ru", "localhost"],
  //     "path": ["/**"],
  //     "places": {
  //       "bxdrg": "/upload/common/places/74697a65722d6e65745f5f174697a65722d6e65745f5f",
  //       "bxdrh": "/upload/common/places/74697a65722d6e65745f5f274697a65722d6e65745f5f",
  //       "bxdri": "/upload/common/places/74697a65722d6e65745f5f374697a65722d6e65745f5f",
  //       "bxdrj": "/upload/common/places/74697a65722d6e65745f5f474697a65722d6e65745f5f"
  //     }
  //   }
  // ],
  _config: null,

  _configRequest: null,

  _getConfig() {
    if (this._config) return Promise.resolve(this._config);
    if (this._configRequest) return this._configRequest;

    this._configRequest = fetch(configUrl, {})
      .then((response) => response.json())
      .then((data) => {
        this._config = data;

        return data;
      })
      .catch((e) => {
        console.error("mosTizer: config of places not loaded.");
        console.error(e);
      });

    return this._configRequest;
  },

  _isHitWithCurrentLocation(path: string, currentLocation: string, excludePaths?: string[]) {
    const isMatchCurrentLocation = (url) => {
      const maskIndex = url.indexOf("**");

      return (
        url === currentLocation || (maskIndex > 0 && new RegExp(`^${url.replace("**", "(.*)")}$`).test(currentLocation))
      );
    };

    if (isMatchCurrentLocation(path)) {
      if (excludePaths && Array.isArray(excludePaths)) {
        return !excludePaths.some((element) => isMatchCurrentLocation(element));
      }

      return true;
    }

    return false;
  },

  async _checkLocationAndFindBannerPath(placeSlug: string) {
    const config = await this._getConfig();

    if (!config) {
      return "";
    }

    const { hostname, pathname } = window.location;
    let result = "";

    config.forEach((configItem) => {
      const { path } = configItem;
      const domains = configItem.domain;
      const excludePaths = configItem.exclude_path;
      const excludeDomains = configItem.exclude_domain;

      if (path) {
        path.forEach((pathItem) => {
          let show = false;

          // Если в конфиге есть массив domain (домены, на которых показывать баннер),
          if (domains) {
            const filtered = domains.filter(
              (domain) => domain === hostname && this._isHitWithCurrentLocation(pathItem, pathname, excludePaths)
            );

            if (filtered.length) {
              show = true;
            }
          }

          // Если в конфиге нет массива domain (домены, на которых показывать баннер),
          // то обязан быть массив exclude_domain (домены, на которых НЕ показывать баннер).

          if (!domains && excludeDomains && !excludePaths) {
            const filtered = excludeDomains.filter((excludeDomain) => excludeDomain === hostname);

            if (!filtered.length && this._isHitWithCurrentLocation(pathItem, pathname, excludePaths)) {
              show = true;
            }
          }

          // Если в секции конфига нет ни domain, ни exclude_domain, то path и exclude_path ни на что не влияют,
          // чтобы какой-то скрипт случайно не вылез на всех доменах *.mos.ru где есть наша шапка.
          // Если необходимость подобного размещения возникнет,
          // можно использовать workaround и указать в exclude_domain несуществующий домен

          if (show) {
            const { places } = configItem;

            if (places && places[placeSlug]) {
              result = places[placeSlug];
              return true;
            }
          }
        });
      }
    });

    return result;
  },

  // TODO: реализовать reload
  reload() {
    return true;
  },

  async create(bannerSettings: BannerSettings) {
    const { element, onRender, onError, params } = bannerSettings;
    const { p1: placeSlug } = params;

    if (!element || !params) return null;

    const bannerPath = await this._checkLocationAndFindBannerPath(placeSlug);

    if (!bannerPath) {
      return null;
    }

    const bannerUrl = `${baseUrl}${bannerPath}/${bannerFileName}`;

    const wrapper = document.createElement("div");
    wrapper.style.width = "100%";
    wrapper.style.height = "100%";

    const iframe = document.createElement("iframe");
    iframe.src = bannerUrl;
    iframe.scrolling = "no";
    iframe.marginWidth = "0";
    iframe.marginHeight = "0";
    iframe.frameBorder = "0";
    iframe.style.height = "100%";
    iframe.style.minHeight = "100%";
    iframe.style.minWidth = "100%";
    iframe.style.width = "1px";
    iframe.style.display = "block";
    iframe.onload = onRender;
    iframe.onerror = () => {
      onError && onError();
      console.error(`mosTizer: banner "${placeSlug}" not found.`);
    };
    iframe.sandbox.add("allow-top-navigation");
    iframe.sandbox.toggle("allow-same-origin");
    iframe.sandbox.toggle("allow-popups");
    iframe.sandbox.toggle("allow-scripts");

    wrapper.appendChild(iframe);
    element.innerHTML = "";
    element.appendChild(wrapper);

    const windowListeners = {};

    return {
      ...bannerSettings,
      iframe,
      windowListeners,
      bannerId: `mosTizerBanner_${placeSlug}`,
      destroy: () => {
        element.innerHTML = "";
        Object.keys(windowListeners).forEach((eventName) => {
          window.removeEventListener(eventName, windowListeners[eventName], false);
        });
      },
    };
  },

  async createAdaptive(bannerSettings: BannerSettings, adaptive, adaptiveOptions) {
    const banner = await this.create(bannerSettings);

    if (!banner) {
      return null;
    }

    if (adaptive && adaptiveOptions && Array.isArray(adaptive)) {
      const { phoneWidth = 480, tabletWidth = 830 } = adaptiveOptions;

      if (phoneWidth && tabletWidth && tabletWidth < phoneWidth) {
        console.error("mosTizer: checkAdaptiveOptions: phoneWidth must be less than tabletWidth");
        return banner;
      }

      if (adaptive.includes("phone") || adaptive.includes("tablet") || adaptive.includes("desktop")) {
        let resizeTimeout;

        const listener = () => {
          const width = document.body.scrollWidth;

          if (adaptive.includes("tablet") || (adaptive.includes("desktop") && tabletWidth && !hasTouchScreen)) {
            if (tabletWidth > width) {
              banner.element.style.display = "none";
            } else {
              banner.element.style.display = "";
            }
          }

          if (adaptive.includes("phone") && phoneWidth && hasTouchScreen) {
            if (width > phoneWidth) {
              banner.element.style.display = "none";
            } else {
              banner.element.style.display = "";
            }
          }
        };

        const throttledListener = () => {
          if (!resizeTimeout) {
            resizeTimeout = setTimeout(() => {
              resizeTimeout = null;
              listener();
            }, 100);
          }
        };

        window.addEventListener("resize", throttledListener, false);
        window.addEventListener("orientationchange", throttledListener, false);
        banner.windowListeners = {
          resize: throttledListener,
          orientationchange: throttledListener,
        };
        listener();
      }
    }

    return banner;
  },

  async createScroll(bannerSettings: BannerSettings) {
    return this.create(bannerSettings);
  },
};

export default adfoxApiReplacer;
